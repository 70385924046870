import React from "react";
import { useTranslation } from "react-i18next";

/* Styles */
import "../../styles/skills.scss";

const Skills = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <main>
        <div className="info"></div>

        <div id="skills">
          <h2>{t("skills-title")}</h2>

          <div className="flex-container">
            <div>
              <h3>{t("basic")}</h3>
              <p>HTML5 - CSS3 / SCSS - JavaScript</p>
            </div>

            <div>
              <h3>Frameworks</h3>
              <p>React.js - Bootstrap 5</p>
            </div>

            <div>
              <h3>App</h3>
              <p>React Native - Ionic</p>
            </div>

            <div>
              <h3>Ekstra</h3>
              <p>Rest Api - GitHub</p>
            </div>

            <div>
              <h3>{t("tools")}</h3>
              <p>Visual Studio Code - Postman</p>
            </div>

            <div>
              <h3>Web</h3>
              <p>W3Schools.com - Stackoverflow</p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Skills;
