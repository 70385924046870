import React from "react";
import "./styles/app.scss";

// LAYOUTS
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";

// PAGES
import About from "./components/pages/About";
import Skills from "./components/pages/Skills";
import Education from "./components/pages/Education";
import Cv from "./components/pages/Cv";

function App() {
  // POGRESS BAR ON SCROLL - Borrowed from;
  // https://www.digitalocean.com/community/tutorials/react-progress-bar-on-scroll

  // When the user scrolls the page, execute myFunction
  window.onscroll = function () {
    myFunction();
  };

  function myFunction() {
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
  }

  return (
    <>
      <div class="progress-container">
        <div class="progress-bar" id="myBar"></div>
      </div>

      <Navbar />

      <About />
      <Skills />
      <Education />
      <Cv />

      <Footer />
    </>
  );
}

export default App;
