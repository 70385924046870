import React from "react";
import { useTranslation } from "react-i18next";

/* Styles */
import "../../styles/education.scss";

const Education = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <section className="education">
        <div className="info"></div>

        <div id="education">
          <h2>{t("education")}</h2>

          <div class="timeline">
            <div class="container left">
              {" "}
              {/* Main Course */}
              <div class="content">
                <h2>Aug 2021 - {t("october")} 2022</h2>
                <h4>
                  {t("main-course")} | WebIT Grenaa <br />
                  <i>Viden Djurs, Grenaa</i>
                </h4>
              </div>
            </div>

            <div class="container right">
              {" "}
              {/* Basic Course 2 */}
              <div class="content">
                <h2>Jan 2021 - Jun 2021</h2>
                <h4>
                  {t("basic-course2")} | {t("web-developer")} <br />
                  <i>AARHUS TECH, Aarhus N</i>
                </h4>
              </div>
            </div>

            <div class="container left">
              {" "}
              {/* Basic Course 1 */}
              <div class="content">
                <h2>Aug 2020 - Jan 2021</h2>
                <h4>
                  {t("basic-course1")} | {t("basic-course1.2")} <br />
                  <i>AARHUS TECH, Viby J</i>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Education;
