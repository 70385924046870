import React from "react";
import "../../styles/about.scss";
import { useTranslation } from "react-i18next";

// ICONS
import { FaLinkedinIn, FaGithub } from "react-icons/fa";

// IMAGES
import MyImage from "../../assets/img/mig.jpg";

const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <header>
        <div className="info">
          <div>
            <h3>{t("name-title")}</h3>
            <p>Tobias Skak Dichmann</p>

            <h3>{t("education")}</h3>
            <h1>{t("job-title")}</h1>

            <h3>Email</h3>
            <a href="mailto:tobias.dichmann@gmail.com">
              <p className="mail">tobias.dichmann@gmail.com</p>
            </a>

            <h3>{t("phone-title")}</h3>
            <a href="tel:+4551461620">
              <p className="phone">+45 51 46 16 20</p>
            </a>
          </div>
        </div>

        <div id="about">
          <div>
            <h3>{t("greeting")}</h3>
            <h2>Tobias Skak Dichmann</h2>

            <p>
              {t("about-text1")}
              <br />
              {t("about-text2")}
            </p>

            <a
              href="https://www.linkedin.com/in/tobias-skak-dichmann-4a3116208/"
              target="_blank"
              title="LinkedIn"
            >
              <FaLinkedinIn />
            </a>

            <a
              href="https://github.com/tobiasdichmann"
              target="_blank"
              title="GitHub"
            >
              <FaGithub />
            </a>
          </div>
        </div>

        <img src={MyImage} alt={t("me-img")} />
      </header>
    </>
  );
};

export default About;
