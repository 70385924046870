import React from "react";
import { useTranslation } from "react-i18next";

/* Styles */
import "../../styles/footer.scss";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer>
      <div className="info"></div>

      <div id="footer">
        <a href="#">{t("back-to-top")}</a>
      </div>
    </footer>
  );
};

export default Footer;
