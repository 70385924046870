import React, { useState } from "react";
import "../../styles/navbar.scss";
import i18n from "../../locales/i18n";
import { useTranslation } from "react-i18next";

// IMAGES
import denmarkFlag from "../../assets/img/denmark-flag.jpg";
import englandFlag from "../../assets/img/england-flag.jpg";

const Navbar = () => {
  // State - Has the burger been clicked? (true/false)
  const [showBurgermenu, setShowBurgermenu] = useState(false);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar">
      {/* BRAND */}
      <a href="#" className="navbar-brand">
        Tobias Skak Dichmann
      </a>

      {/* BURGERMENU */}
      <div
        className={showBurgermenu ? "toggle-btn change" : "toggle-btn"}
        onClick={() => setShowBurgermenu(!showBurgermenu)}
      >
        <span className="bar bar1"></span>
        <span className="bar bar2"></span>
        <span className="bar bar3"></span>
      </div>

      {/* NAV - LINKS */}
      <div className={showBurgermenu ? "navbar-links active" : "navbar-links"}>
        <ul>
          <li>
            <a href="#about">{t("about-link")}</a>
          </li>
          <li>
            <a href="#skills">Skills</a>
          </li>
          <li>
            <a href="#education">{t("education")}</a>
          </li>
          <li>
            <a href="#cv">Cv</a>
          </li>

          {/* <div className="dropdown">
            <span>{t("language")}</span>
            <div className="dropdown-content">
              <button onClick={() => changeLanguage("da")}>
                <img src={denmarkFlag} alt={t("denmark-alt")} />
                <p>{t("danish")}</p>
              </button>
              <button onClick={() => changeLanguage("en")}>
                <img src={englandFlag} alt="Englands flag" />
                <p>{t("english") }</p>
              </button>
            </div>
          </div> */}

          <li className="dropdown">
            <a href="#">{t("language")}</a>

            <div className="dropdown-pad">
              <div className="dropdown-content">
                <button onClick={() => changeLanguage("da")}>
                  <img src={denmarkFlag} alt={t("denmark-alt")} />
                  <p>{t("danish")}</p>
                </button>
                <button onClick={() => changeLanguage("en")}>
                  <img src={englandFlag} alt={t("denmark-alt")} />
                  <p>{t("english")}</p>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default Navbar;
