import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationDA from './da/translationDA.json';
import translationEN from './en/translationEN.json';

// The translations
const resources = {
    da: {
        translation: translationDA
    },
    en: {
        translation: translationEN
    }
};

i18n
    .use(detector)
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en', // Use en if detected lng is not available

        keySeparator: false, // We do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // React already safes from xss
        }
    });

export default i18n;