import React from "react";
import { useTranslation } from "react-i18next";

/* Styles */
import "../../styles/cv.scss";

import MyCV from "../../assets/tobias-skak-dichmann_cv.pdf";

const Cv = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <section className="cv">
        <div className="info"></div>

        <div id="cv">
          <a
            href={MyCV}
            className="cv-download"
            title={t("danish") + " CV"}
            download
          >
            Download CV
          </a>
          <a
            href={MyCV}
            className="cv-tab"
            title={t("danish") + " CV"}
            target="_blank"
          >
            {t("open-cv")}
          </a>
        </div>
      </section>
    </>
  );
};

export default Cv;
